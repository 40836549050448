.tooltip-width {
  max-width: 700px;
}
.home-list-wrapper .search-form {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.home-list-wrapper .search-form .tb-form-item-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
}
.home-list-wrapper .search-form .tb-form-item-search .el-form-item__label {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #303133;
}
.home-list-wrapper .plan-list-wrap {
  height: 100%;
  text-align: left;
}
.home-list-wrapper .plan-list-wrap > li {
    border: #e5e5e5 1px solid;
    margin-bottom: 10px;
    background: #ffffff;
    font-size: 14px;
}
.home-list-wrapper .plan-list-wrap > li:hover {
      border-color: #409eff;
      cursor: pointer;
}
.home-list-wrapper .plan-list-wrap > li .list-title {
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      background: #f5f5f5;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.home-list-wrapper .plan-list-wrap > li .list-title .plan-title {
        display: inline-block;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
}
.home-list-wrapper .plan-list-wrap > li .list-title .hd-tag {
        font-size: 14px;
        line-height: 1;
        padding: 0px 15px;
        height: 24px;
        line-height: 24px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-radius: 12px;
        margin-left: 10px;
}
.home-list-wrapper .plan-list-wrap > li .list-title .hd-state-tag {
        border: 1px solid #409eff;
        color: #409eff;
}
.home-list-wrapper .plan-list-wrap > li .list-title .hd-type-tag {
        border: 1px solid #8894f2;
        color: #8894f2;
}
.home-list-wrapper .plan-list-wrap > li .list-title .hd-subject-tag {
        border: 1px solid #ffa112;
        color: #ffa112;
}
.home-list-wrapper .plan-list-wrap > li .plan-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding: 20px;
      color: #303133;
      font-weight: 400;
}
.home-list-wrapper .plan-list-wrap > li .plan-list > li {
        padding-bottom: 15px;
        width: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
}
.home-list-wrapper .plan-list-wrap > li .plan-list > li .row-title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
}
.home-list-wrapper .plan-list-wrap > li .plan-list > li .row-title span {
            display: block;
            width: 70px;
            -moz-text-align-last: justify;
                 text-align-last: justify;
}
.home-list-wrapper .plan-list-wrap > li .plan-list > li .row-desc {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
}
.home-list-wrapper .plan-list-wrap > li .handle-btns {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 48px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      border-top: #e5e5e5 1px solid;
      text-align: right;
      padding-right: 40px;
}
.home-list-wrapper .plan-list-wrap > li .handle-btns button {
        margin-left: 30px;
}
.home-list-wrapper .plan-list-wrap > li .handle-btns button i {
          margin-right: 4px;
}
.home-list-wrapper .plan-list-wrap .el-button--text {
    border: solid;
    padding-top: 5px;
    padding-bottom: 5px;
    border-width: 1px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px;
}
